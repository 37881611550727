<template>
  <div>
    <form-wizard
      :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading.submit ? 'Updating...' : 'Update'"
      class="mb-3"
      @on-complete="formSubmitted()"
    >
      <!-- leave_application details tab -->
      <tab-content
        title="Leave Application Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="leave_application-form">
            <b-row>
              <b-col md="5">
                <b-form-group label="Leave" label-for="leave">
                  <v-select
                    v-model="data_local.leave_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="leave_list"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="leave_type_id"
                    name="leave_type_id"
                    @input="onChange($event)"
                    placeholder="Select Leave Type"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-row v-if="userBalances && selected_leave_type" class="py-2">
                  <b-col>
                    <b-badge variant="light-info"
                      >Balance :
                      {{
                        userBalances[selected_leave_type]["balance"]
                      }}</b-badge
                    >
                  </b-col>
                  <b-col>
                    <b-badge variant="light-warning"
                      >Approval Pending :
                      {{
                        userBalances[selected_leave_type]["approval_pending"]
                      }}</b-badge
                    >
                  </b-col>
                  <b-col>
                    <b-badge variant="light-primary"
                      >Actual Balance :
                      {{
                        userBalances[selected_leave_type]["actual_balance"]
                      }}</b-badge
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="5" v-if="selected_leave_type && userBalances[selected_leave_type]['usability_type'] == 'non-splittable'">
                <b-form-group label="Select Start Date" label-for="from_date_1">
                  <!-- v-show="data_local.leave_type_id != 6" -->
                  <flat-pickr
                    ref="from_date"
                    v-model="data_local.dates"
                    :config="singledateConfig"
                    class="form-control"
                    input-id="from_date_1"
                    @input="pickBulkDates"
                    placeholder="Select Start Date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="5" v-else>
                <b-form-group label="Dates" label-for="from_date_1">
                  <!-- v-show="data_local.leave_type_id != 6" -->
                  <flat-pickr
                    ref="from_date"
                    v-model="data_local.dates"
                    :config="dateConfig"
                    class="form-control"
                    input-id="from_date_1"
                    @input="checkDates"
                    placeholder="Select Dates"
                  />

                  <!-- <flat-pickr
                    v-show="data_local.leave_type_id == 6"
                    v-model="data_local.from_date1"
                    :config="rDateConfig"
                    class="form-control"
                    input-id="from_date_2"
                    @input="checkDates"
                    placeholder="Select From Date R"
                  /> -->
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-row class="py-2" v-if="selected_leave_type && userBalances[selected_leave_type]['usability_type'] == 'non-splittable' && bulkdates">
                  <b-col>
                    <b-badge variant="light-info"
                      >Days Count :
                      {{
                        bulkdates.length
                      }}</b-badge
                    >
                  </b-col>
                  <b-col>
                    <b-badge variant="light-warning"
                      >From Date
                      {{
                        bulkdates[0]
                      }}</b-badge
                    >
                  </b-col>
                  <b-col>
                    <b-badge variant="light-primary"
                      >TO Date:
                      {{
                        bulkdates[bulkdates.length-1]
                      }}</b-badge
                    >
                  </b-col>
                </b-row>

                <b-form-group label="Session From" label-for="from-session">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.session_from"
                      value="session_1"
                    >
                      Session 1
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.session_from"
                      value="session_2"
                    >
                      Session 2
                    </b-form-radio>
                  </div>
                </b-form-group>

                <b-form-group label="Session To" label-for="to-session">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.session_to"
                      value="session_1"
                    >
                      Session 1
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.session_to"
                      value="session_2"
                    >
                      Session 2
                    </b-form-radio>
                  </div>
                </b-form-group>
            
                <b-form-group v-if="checkIfRequired()" label="Reason for the leave" label-for="reason">
                  <b-form-textarea
                    id="reason"
                    v-model="data_local.reason"
                    placeholder="Enter your reason for this leave application"
                    rows="3"
                  />
                </b-form-group>
             
                <b-form-group
                  label="Contact Details"
                  label-for="contact_details"
                >
                  <b-form-textarea
                    id="contact_details"
                    v-model="data_local.contact_details"
                    placeholder="Enter your contact details"
                    rows="3"
                  />
                </b-form-group>
              </b-col>

              <b-form-group
                v-for="item in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import leaveApplicationStoreModule from "../leaveApplicationStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectLeaveApplication: "",
      data_local: {
        dates: "",
        reason: "",
        get_customfields_data: [],
      },
      customFields: [],
      userBalances: {
        "Casual Leave": { balance: 0, approval_pending: 0, actual_balance: 0 ,usability_type:0, days_type:0},
      },
      leave_list: [],
      selected_leave_type: null,
      fromDateValid: false,
      toDateValid: false,
      holidays: [],
      singledateConfig:{
        dateFormat: "Y-m-d",
          inline: true,
          mode: "single",
      },
      dateConfig: {
        dateFormat: "Y-m-d",
        // disable: [
        //     function(date) {
        //       return (date.getDay() === 0 || date.getDay() === 6 );
        //     }
        // ],
        inline: true,
        mode: "multiple",
      },
      bulkdates :[],
      userData :this.$cookies.get("userData"),
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-leave_applications";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        leaveApplicationStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetchLeaveBalance();
    this.fetchLeaveList();
    this.fetchUserHolidays();
    this.fetchLeaveApplicationData(this.$route.params.id);
    this.selected_leave_type = this.data_local.leave_type;
  },
  methods: {
    getRequireReasonToApplyByCode: function (code) {
      for (const item of this.leave_list) {
        if (item.code === code) {
          return item.require_reason_to_apply;
        }
      }
      return null;
    },
    checkIfRequired: function () {
      if (this.data_local.leave_type_id) {
        return this.getRequireReasonToApplyByCode(this.data_local.leave_type_id);
      }
      else {
        return false;
      }
    },
    openWindow(link) {
      window.open(link);
    },
    fetchLeaveApplicationData(userId) {
      this.$store
        .dispatch("app-leave_applications/fetchLeaveApplication", userId)
        .then((res) => {
          this.data_local = res.data.data;
          this.selected_leave_type = this.data_local.leave_type;
          if(this.userBalances[this.selected_leave_type]['usability_type'] == 'non-splittable'){
            //if its non splittable one from date is enough for single mode datepicker.
            console.log(this.data_local.dates);
            this.bulkdates = this.data_local.dates;
            this.data_local.dates = this.bulkdates[0];
          }
        })
        .catch((err) => {
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(
        document.getElementById("leave_application-form")
      );
      formData.append("dates", self.data_local.dates);
      formData.append("session_from", self.data_local.session_from);
      formData.append("session_to", self.data_local.session_to);
      formData.append("reason", self.data_local.reason);
      formData.append("contact_details", self.data_local.contact_details);
      formData.append("leave_type_id", self.data_local.leave_type_id);
      formData.append("_method", "PATCH");
      if(self.userBalances[self.selected_leave_type]['usability_type'] == 'non-splittable'){
          formData.append("dates", self.bulkdates.join(','));
        }
      this.is_loading.submit = true;

      this.$store
        .dispatch("app-leave_applications/updateLeaveApplication", {
          id: self.$route.params.id,
          data: formData,
        })
        .then((res) => {
          if (res.data.success) {
            this.is_loading.submit = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Leave Application Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            this.$router.push("/leave-applications");
          } else {
            this.is_loading.submit = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Leave Application Updation Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.is_loading.submit = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Leave Application updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    onChange(e) {
      for (let i = 0; i < this.leave_list.length; i++) {
        if (this.leave_list[i]["code"] == e) {
          this.selected_leave_type = this.leave_list[i]["label"];
        }
      }
      if (!this.userBalances[this.selected_leave_type]) {
        this.userBalances[this.selected_leave_type] = {
          balance: 0,
          approval_pending: 0,
          actual_balance: 0,
          usability_type: 0,
          days_type:0,
        };
      }
      // this.$refs.from_date.fp.clear();
    },
    pickBulkDates(start_date){
      if (!start_date) return;
      console.log(start_date);
      console.log(this.userBalances[this.selected_leave_type]);
      var dates = this.addWorkDays(new Date(start_date),this.userBalances[this.selected_leave_type]['fixed_limit'],this.userBalances[this.selected_leave_type]['days_type']);
      this.bulkdates = dates;
      this.fromDateValid = true;
    },
    checkDates(selectedDate) {
      if (!selectedDate) return;
      console.log(selectedDate);
      selectedDate.split(",").forEach((date) => {
        if (this.holidays.includes(date)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "The 'From' selected day '" +
                date +
                "' is already Holiday for your shift",
              icon: "BellIcon",
              variant: "warning",
            },
          });
          this.fromDateValid = false;
        } else {
          if (typeof date !== "undefined") {
            this.fromDateValid = true;
          }
        }
      });
    },
    checkFromDate(selectedDate) {
      if (this.holidays.includes(selectedDate)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "The 'From' selected day '" +
              selectedDate +
              "' is already Holiday for your shift",
            icon: "BellIcon",
            variant: "warning",
          },
        });
        this.fromDateValid = false;
      } else {
        if (typeof selectedDate !== "undefined") {
          this.fromDateValid = true;
        }
      }
    },
    checkToDate(selectedDate) {
      if (
        this.holidays.includes(selectedDate) &&
        typeof selectedDate !== "undefined"
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "The 'To' selected day '" +
              selectedDate +
              "' is already Holiday for your shift",
            icon: "BellIcon",
            variant: "warning",
          },
        });
        this.toDateValid = false;
      } else {
        if (typeof selectedDate !== "undefined") {
          this.toDateValid = true;
        }
      }
    },
    fetchLeaveList() {
      this.isloading = true;
      this.$store
        .dispatch("app-leave_applications/fetchLeaveList")
        .then((response) => {
          this.leave_list = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchUserHolidays() {
      this.isloading = true;
      this.$store
        .dispatch("app-leave_applications/fetchUserHolidays", { year: "2022", shift : this.userData.shift_details.id })
        .then((response) => {
          var holidays = response.data.data;
          for (let i = 0; i < holidays.length; i++) {
            if(holidays[i].holiday_type != 'Restricted Holiday'){
            this.holidays.push(holidays[i].date);
            }
          }
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchLeaveBalance() {
      const self = this;
      self.$store
        .dispatch("app-leave_applications/fetchLeaveBalance")
        .then((res) => {
          self.userBalances = res.data.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-leave_applications/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    addWorkDays(startDate, days,daysType) {
      if(isNaN(days)) {
          console.log("Value provided for \"days\" was not a number");
          return
      }
      if(!(startDate instanceof Date)) {
          console.log("Value provided for \"startDate\" was not a Date object");
          return
      }
      var dates = [];
      var lastdate = startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+startDate.getDate();
      dates.push(lastdate);
      days--;
      lastdate = startDate;
      while(days > 0){
        console.log(lastdate.getDay())
        lastdate.setDate(lastdate.getDate() + 1);
        console.log(lastdate.getDay())
        if(daysType == 'working_days'){
          if(lastdate.getDay() != 0 && lastdate.getDay() != 6){
            dates.push(lastdate.getFullYear()+"-"+(lastdate.getMonth()+1)+"-"+lastdate.getDate());
            console.log(dates)
            days--;   
          }       
        }else{
          dates.push(lastdate.getFullYear()+"-"+(lastdate.getMonth()+1)+"-"+lastdate.getDate());
            console.log(dates)
            days--;
        }
      }
      return dates;
      // // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
      // var dow = startDate.getDay();
      // var daysToAdd = parseInt(days);
      // // If the current day is Sunday add one day
      // if (dow == 0)
      //     daysToAdd++;
      // // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
      // if (dow + daysToAdd >= 6) {
      //     //Subtract days in current working week from work days
      //     var remainingWorkDays = daysToAdd - (5 - dow);
      //     //Add current working week's weekend
      //     daysToAdd += 2;
      //     if (remainingWorkDays > 5) {
      //         //Add two days for each working week by calculating how many weeks are included
      //         daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      //         //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      //         if (remainingWorkDays % 5 == 0)
      //             daysToAdd -= 2;
      //     }
      // }
      // startDate.setDate(startDate.getDate() + daysToAdd);
      // return startDate;
  }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
